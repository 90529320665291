import React, { useState } from "react";
import { blockContentToPlainText } from "react-portable-text";
import { slugify, artistname } from "../../lib/utils";
import styled from "styled-components";
import ArtistMasonry from "../artist_masonry";
import Image from "gatsby-plugin-sanity-image";
import getVideoId from "get-video-id";
import ArtistIntro from "../artist_intro";
import ArtistSidebar from "../artist_sidebar";
import { useLocation } from "@reach/router";
import ArtistAbout from "../artist_about";
import ArtistCredits from "../artist_credits";
import ArtistNews from "../artist_news";
import ArtistPodcasts from "../artist_podcasts";
import Earmark from "../../images/earmark.svg";
import SEO from "../seo";
import Sounds from "../sounds";
import ArtistVideos from "../artist_videos";
import ArtistSoundclouds from "../artist_soundclouds";
import { useStaticQuery, graphql, navigate } from "gatsby";

const Main = styled.main`
  max-width: calc(1400px - 2rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  column-gap: 5rem;
  @media (max-width: 1024px) {
    padding-left: 3rem;
    padding-right: 3rem;
    column-gap: 3rem;
  }
  @media print {
    padding-top: 0;
  }
  @media (max-width: 880px) {
    flex-direction: column;
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0;
    max-width: calc(100vw - 1rem);
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;
`;

const ArtistPrintIntro = styled.div`
  display: flex;
  padding-left: 1rem;
  margin-bottom: 4rem;
  column-gap: 4rem;
  @media (max-width: 880px) {
    flex-direction: column;
    column-gap: 0;
  }
  @media print {
    flex-direction: row;
    column-gap: 4rem;
  }
  div.left {
    @media (max-width: 880px) {
      display: none;
    }
    @media print {
      display: block;
    }
  }
  div.right {
    padding-top: 1.5em;
    @media (max-width: 880px) {
      padding-top: 0;
    }
    @media print {
      padding-top: 0.25rem;
    }
    h1 {
      margin-bottom: 2rem;
      font-size: 50px;
      line-height: normal;
      @media print {
        margin-bottom: 0.5rem;
      }
    }
    a {
      font-family: Catamaran, sans-serif;
      text-decoration: none;
      padding: 0.25rem 1.5rem;
      font-weight: 600;
    }
    a.print-button {
      border-radius: 0.25rem;
      border: 1px solid var(--pink);
    }
  }
`;

const Menu = styled.ul`
  list-style: none;
  width: 100%;
  padding-left: 0;
  border-bottom: 1px solid #e4e6ea;
  @media (max-width: 880px) {
    display: none;
  }
  li {
    display: inline-block;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #e4e6ea;
    background-color: #fff;
    transition: all 0.25s ease-in-out;
    transform: translateY(1px);
    &:hover {
      a {
        color: var(--pink);
      }
    }
    &.active {
      border-bottom: 1px solid #fff;
      border-left: 1px solid #e4e6ea;
      border-top: 1px solid #e4e6ea;
      border-right: 1px solid #e4e6ea;
      a {
        color: var(--pink);
        font-weight: 800;
      }
    }
    a {
      font-family: "Catamaran", sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #000;
      transition: all 0.25s ease-in-out;
      text-decoration: none;
    }
    color: #000;
    @media (max-width: 1196px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;

const Sections = styled.div`
  padding-top: 2rem;
  @media print {
    padding-top: 0;
  }
  @media (max-width: 880px) {
    padding-top: 1rem;
  }
`;

const Anchor = styled.a``;

const SectionContainer = styled.div`
  transition: all 0.25s ease-in-out;
  opacity: 0;
  img.gallery {
    padding: 0 0 22px 0;
  }
  &.open {
    opacity: 1;
    display: block;
  }
  &.closed {
    opacity: 0;
    display: none;
  }
  @media (max-width: 880px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .react-photo-album {
      flex-direction: column;
    }
    .react-photo-album--column {
      width: 100% !important;
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`;

const Print = styled.div`
  position: absolute;
  &.print {
    top: 6rem;
    right: 1rem;
    @media (max-width: 880px) {
      top: 1rem;
    }
  }
  &.close {
    top: 1rem;
    right: 1rem;
  }
  a {
    font-family: Catamaran, sans-serif;
    span {
      font-family: Catamaran, sans-serif;
    }
  }
`;
const PhotoContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: 2rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  a {
    width: 100%;
  }
  img {
    width: 100%;
  }
`;

const MenuMobile = styled.div`
  display: none;
  @media (max-width: 880px) {
    display: ${(props) => (props.section === "print" ? "none" : "block")};
    background-color: var(--pink);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    overflow-x: scroll;
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: space-between;
      li {
        display: inline-block;
        margin: 0;
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
        width: 100%;
        min-width: 100px;
        text-align: center;
        border-right: 1px solid #f367a5;
        &:last-of-type {
          border-right: none;
        }
        &.active a {
          color: #000;
        }
        a {
          color: #fff;
          display: inline-block;
          font-weight: 600;
          text-decoration: none;
          text-transform: uppercase;
          font-family: "Catamaran", sans-serif;
        }
      }
    }
  }
`;

const Earmarkbox = styled.span`
  color: var(--pink);
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  a {
    font-family: Catamaran, sans-serif;
  }
`;

const Artist = (props) => {
  const location = useLocation();
  const default_tab = location?.hash === "#podcasts" ? "podcasts" : "about";
  const [section, setSection] = useState(default_tab);
  const [numNews, setNumNews] = useState(0);
  const {
    artist_audio,
    artist_video,
    _id,
    gallery,
    showreel,
    headshot,
    sounds,
  } = props;

  let video_id;
  let video_embed_url;

  if (showreel) {
    video_id =
      showreel && showreel.includes("youtu")
        ? showreel.split("=").slice(-1)[0]
        : showreel.split("vimeo.com/").slice(-1)[0];
    video_embed_url =
      video_id && video_id.match(/^[0-9]+$/) != null
        ? "https://player.vimeo.com/video/" + video_id
        : "https://www.youtube.com/embed/" + video_id;
  }

  const artist_name = artistname(props);

  const podcasts = props?.artist_podcasts;

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(
        filter: {
          artist_podcasts: {
            elemMatch: {
              related_artists: { elemMatch: { id: { ne: "null" } } }
            }
          }
        }
      ) {
        edges {
          node {
            _id
            artist_podcasts {
              _key
              podcast_acast
              podcast_amazon
              podcast_apple
              podcast_bbc
              podcast_google
              podcast_pocketcasts
              podcast_spotify
              related_artists {
                _id
                id
              }
              description
              guest_appearance
              title
              image {
                ...ImageWithPreview
                asset {
                  url
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const podcasts_with_related_artists = data.allSanityArtist.edges;

  const related_podcasts = [];

  podcasts_with_related_artists.map((p) => {
    const podcast = p.node.artist_podcasts;
    podcast.map((q) => {
      q.related_artists.map((r) => {
        if (r._id === _id) {
          related_podcasts.push(q);
        }
      });
    });
  });

  const handleSection = (e, section) => {
    e.preventDefault();
    setSection(section);
    // navigate(location.path)
  };

  const photos = gallery.map((g) => {
    return {
      caption: g.caption,
      attribution: g.attribution,
      src: g.asset.localFile.publicURL,
      preview: g.asset.biggerLocalFile.publicURL,
      dl: g.asset.url + "?fm=jpg&dl=",
      width: g.asset.width,
      height: g.asset.height,
    };
  });

  const has_media =
    (photos && photos.length > 0) || showreel
      ? true
      : false || (artist_video && artist_video.length > 0)
        ? true
        : false;

  const description =
    blockContentToPlainText(props.biography_blocks).split(".")[0] + ".";
  const slug = "/artists/" + slugify(artist_name);
  const meta_image = headshot.asset.publicUrl;

  return (
    <>
      <SEO
        image={meta_image}
        title={artist_name}
        description={description}
        slug={slug}
      />

      {section !== "print" && (
        <ArtistIntro {...props} setSection={setSection} />
      )}

      <MenuMobile section={section} className="no-print">
        <nav className="no-print">
          <ul>
            <li className={section === "about" ? "active" : ""}>
              <a href="#" onClick={(e) => handleSection(e, "about")}>
                About
              </a>
            </li>
            {props?.credits && (
              <li className={section === "credits" ? "active" : ""}>
                <a href="#" onClick={(e) => handleSection(e, "credits")}>
                  Credits
                </a>
              </li>
            )}

            {has_media && (
              <li className={section === "media" ? "active" : ""}>
                <a href="#" onClick={(e) => handleSection(e, "media")}>
                  Media
                </a>
              </li>
            )}

            {numNews > 0 && (
              <li className={section === "news" ? "active" : ""}>
                <a href="#" onClick={(e) => handleSection(e, "news")}>
                  News
                </a>
              </li>
            )}

            {((podcasts && podcasts.length > 0) ||
              related_podcasts.length > 0) && (
                <li className={section === "podcasts" ? "active" : ""}>
                  <a href="#" onClick={(e) => handleSection(e, "podcasts")}>
                    Podcasts
                  </a>
                </li>
              )}
          </ul>
        </nav>
      </MenuMobile>
      <Main>
        {section !== "print" && <ArtistSidebar section={section} {...props} />}

        <Anchor name="about"></Anchor>

        <Content>
          {section !== "print" && (
            <Menu>
              <li className={section === "about" ? "active" : ""}>
                <a href="#" onClick={(e) => handleSection(e, "about")}>
                  About
                </a>
              </li>

              {props?.credits && (
                <li className={section === "credits" ? "active" : ""}>
                  <a href="#" onClick={(e) => handleSection(e, "credits")}>
                    Credits
                  </a>
                </li>
              )}

              {has_media && (
                <li className={section === "media" ? "active" : ""}>
                  <a href="#" onClick={(e) => handleSection(e, "media")}>
                    Media
                  </a>
                </li>
              )}

              {numNews > 0 && (
                <li className={section === "news" ? "active" : ""}>
                  <a href="#" onClick={(e) => handleSection(e, "news")}>
                    News
                  </a>
                </li>
              )}
              {((podcasts && podcasts.length > 0) ||
                related_podcasts.length > 0) && (
                  <li className={section === "podcasts" ? "active" : ""}>
                    <a href="#" onClick={(e) => handleSection(e, "podcasts")}>
                      Podcasts
                    </a>
                  </li>
                )}
            </Menu>
          )}

          <Sections>
            <SectionContainer
              className={section === "about" ? "open" : "closed"}
            >
              <ArtistAbout {...props} />
            </SectionContainer>

            <SectionContainer
              className={section === "credits" ? "open" : "closed"}
            >
              <Print className="print">
                <a
                  href="#"
                  onClick={(e) =>
                    handleSection(e, section === "print" ? "credits" : "print")
                  }
                >
                  {section === "print" ? (
                    "Close"
                  ) : (
                    <Earmarkbox>
                      View full credits <img src={Earmark} alt="" />{" "}
                    </Earmarkbox>
                  )}
                </a>
              </Print>
              <ArtistCredits {...props} />
            </SectionContainer>

            {section === "print" && (
              <>
                <ArtistPrintIntro>
                  <div className="left">
                    <Image
                      {...headshot}
                      width={180}
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="right">
                    <h1>
                      {props.first_name} {props.surname}
                    </h1>
                    <p className="print-only">
                      {props.contact_1_role}: {props.contact_1_name.title}
                      <br />
                      {props.contact_1_name.email}
                      <br />
                      {props.contact_1_name.phone}
                    </p>
                    <div className="no-print">
                      <a
                        className="print-button"
                        href="#"
                        onClick={(e) => window.print()}
                      >
                        Print this page
                      </a>{" "}
                      <a href="#" onClick={(e) => handleSection(e, "credits")}>
                        Back to Main Listing
                      </a>
                    </div>
                  </div>
                </ArtistPrintIntro>
                <ArtistCredits {...props} />
              </>
            )}

            <SectionContainer
              className={section === "media" ? "open" : "closed"}
            >
              {photos && <ArtistMasonry photos={photos} />}

              {showreel && (
                <VideoContainer>
                  <iframe
                    title="showreel"
                    width="100%"
                    height="100%"
                    src={video_embed_url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  ></iframe>
                </VideoContainer>
              )}

              {artist_video && <ArtistVideos videos={artist_video} />}

              {artist_audio && section === "media" && (
                <ArtistSoundclouds soundclouds={artist_audio} />
              )}

              {sounds && <Sounds sounds={sounds} />}
            </SectionContainer>

            <SectionContainer
              className={section === "news" ? "open" : "closed"}
            >
              <ArtistNews
                id={_id}
                setNumNews={setNumNews}
                artist_name={artist_name}
              />
            </SectionContainer>

            {(podcasts || related_podcasts) && (
              <SectionContainer
                className={section === "podcasts" ? "open" : "closed"}
              >
                <ArtistPodcasts
                  related_podcasts={related_podcasts}
                  podcasts={podcasts}
                />
              </SectionContainer>
            )}
          </Sections>
        </Content>
      </Main>
    </>
  );
};

export default Artist;
